<template>
    <b-row class="characteristics mt-40">
        <b-col cols="12">
            <h5>{{ $t('page.product.specificationHeading') }}</h5>
            <b-row
                v-for="(item, index) in product.characteristics"
                :key="`characteristics-${item.name}-${index}`"
                class="g-0"
            >
                <b-col
                    class="col"
                    cols="12"
                    lg="3"
                    md="4"
                >
                    <p class="sbold name">
                        {{ item.name }}
                    </p>
                </b-col>
                <b-col
                    cols="12"
                    lg="9"
                    md="8"
                >
                    <p>{{ item.value }}</p>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: 'ProductCharacteristics',
        computed: {
            ...mapState('product', ['product', 'loading']),
        },
    };
</script>
<style scoped lang="scss">
.characteristics {
  .row {
    &:first-of-type {
      margin-top: 24px;
      @include media-breakpoint-down(md) {
        margin-top: 12px;
      }
    }
    padding: 8px;
    &:nth-of-type(odd) {
      background-color: $gray-thin;
    }
    .col {
      p {
        color: $text;
        &.name{
          &::first-letter{
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
</style>
